import * as React from 'react'
import styled from 'styled-components'

import * as C from '../components'
import * as S from '../styles'

import { Info, Details, AddToCart, Images, Related, Story } from '../product'

const Mobile = styled.div`
  display: none;
  @media (max-width: 767px) {
    display: block;
  }
`

const Desktop = styled.div`
  display: block;
  @media (max-width: 767px) {
    display: none;
  }
`

const Product: React.SFC = () => (
  <C.App headerTheme="dark">
    <S.Section className="intro product-intro">
      <S.Wrapper>
        <Mobile>
          <Info />
          <Images />
          <Details />
          <AddToCart />
        </Mobile>
        <Desktop>
          <S.Grid>
            <S.GridItem
              width="48%"
              breakpoints={{
                767: '100%',
              }}>
              <Info />
              <Details />
              <AddToCart />
            </S.GridItem>
            <S.GridItem
              width="48%"
              breakpoints={{
                767: '100%',
              }}>
              <Images />
            </S.GridItem>
          </S.Grid>
        </Desktop>
      </S.Wrapper>
    </S.Section>
    <Story />
    <C.JoinCommunity />
  </C.App>
)

export default Product
